import React from "react";
import App from "next/app";
import RootLayout from "../app/layouts/layout";
// import GlobalHeader from "../app/components/globalHeader";
import GlobalFooter from "../app/components/globalFooter";
import "../app/globals.css";

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    return (
      <RootLayout>
        {/* <GlobalHeader /> */}
        <Component {...pageProps} screen={"register"} />
        <GlobalFooter />
      </RootLayout>
    );
  }
}

export default MyApp;
